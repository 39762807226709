// Tabs
.Polaris-Tabs__Tab:focus:not(:active) .Polaris-Tabs__Title::after,
.Polaris-Tabs__DisclosureActivator:focus .Polaris-Tabs__Title::after {
    box-shadow: none !important;
}

// Items wrap
.items-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: flex-start;
    padding-bottom: 2rem;

    .item-wrap {
        border-radius: var(--p-border-radius-wide, 3px);
        background-color: var(--p-surface);
        box-shadow: var(
            --p-card-shadow,
            0 0 0 1px rgba(63, 63, 68, 0.05),
            0 1px 3px 0 rgba(63, 63, 68, 0.15)
        );
        outline: 0.1rem solid transparent;
        padding: 1.6rem;
        position: relative;
        cursor: pointer;
        width: calc(25% - 2.3rem);
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

// Responsive
/// Screen: sm: (40em | 640px) ; md: (48em | 768px) ; lg: (64em | 1024px) ,xl: (80em | 1280px) , 2xl: (96em | 1536px);

@media (max-width: 80em) {
    .items-wrap {
        .item-wrap {
            width: calc(30% - 0.1rem);
        }
    }
}

@media (max-width: 48em) {
    .items-wrap {
        .item-wrap {
            width: calc(50% - 1.8rem);
        }
    }
}

@media (max-width: 40em) {
    .items-wrap {
        .item-wrap {
            width: calc(100% - 1rem);
        }
    }
}

@media (min-width: 96em) {
    .items-wrap {
        .item-wrap {
            width: calc(18% - 0.1rem);
        }
    }
}
